var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-details'
                    : 'archive-project-details',
                params: {
                    project: _vm.localProject,
                    ...(_vm.localQuotes ? { quotes: _vm.localQuotes } : {}),
                    clients: _vm.localClients,
                    users: _vm.localUsers,
                },
            }}},[_vm._v(" Project Details ")]),(_vm.user.permissions.includes('readProjectQuotes'))?_c('v-tab',{attrs:{"disabled":!_vm.localQuotes,"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-quotes'
                    : 'archive-project-quotes',
                params: {
                    project: _vm.localProject,
                    ...(_vm.localQuotes ? { projectQuotes: _vm.localQuotes } : {}),
                    clients: _vm.localClients,
                    users: _vm.localUsers,
                    settings: _vm.settings,
                },
            }}},[_vm._v(" Quotes ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'tasks-by-project'
                    : 'tasks-by-archived-project',
                params: {
                    projectId: _vm.localProject.id,
                    avoidEmptyUsers: false,
                },
            }}},[_vm._v(" Tasks ")]),(_vm.quoteToFollowUp)?_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-quote-follow-up'
                    : 'archive-project-quote-follow-up',
                params: {
                    id: _vm.quoteToFollowUp.id,
                    quote: _vm.quoteToFollowUp,
                    settings: _vm.settings,
                },
            }}},[_vm._v(" Follow Up ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-reserves'
                    : 'archive-project-reserves',
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" Reserves ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-purchases'
                    : 'archive-project-purchases',
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" Purchases ")]):_vm._e(),(_vm.localProject && this.readPartNumbers)?_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-part-numbers'
                    : 'archive-project-part-numbers',
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" PART NUMBERS ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-work-orders'
                    : 'archive-project-work-orders',
                params: {
                    projectId: _vm.localProject.id,
                    project: _vm.localProject,
                },
            }}},[_vm._v(" WO ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-bill-of-materials'
                    : 'archive-project-bill-of-materials',
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" BOM ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-packing-lists'
                    : 'archive-project-packing-lists',
                params: {
                    project: _vm.localProject,
                },
            }}},[_vm._v(" PACKING LISTS ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'b2b-forecast'
                    : 'archive-b2b-forecast',
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" B2B Forecast ")]),_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-invoices'
                    : 'archive-project-invoices',
                params: {
                    project: _vm.localProject,
                },
            }}},[_vm._v(" INVOICES ")]),_c('v-tab',{attrs:{"to":{
                name: !this.nameRoute.includes('archive-project')
                    ? 'project-logs'
                    : 'archive-project-logs',
                params: {
                    id: _vm.localProject.id,
                },
            }}},[_vm._v(" LOGS ")])],1),_c('v-card',[_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px`)},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('Errors')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }